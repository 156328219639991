import { BlockManager, CardAnchorContainer } from "./block.js";
import { constructHref, dateToString, fetchNews, fetchContent } from "./helpers.js";

// import dummyPicture from "../img/dummy.svg";

const menuHeight = document.getElementById("menu").clientHeight;
document.getElementById("main-block").style.height = `calc(100dvh - ${menuHeight}px)`;

document.getElementById("dfa-issue-button").href = constructHref("/dfa-issue");
document.getElementById("invest-button").href = constructHref("/invest");
document.getElementById("all-news-button").href = constructHref("/news");

Promise.all([
    fetchContent("index").then((data) => {
        document.getElementById("whats-madrigal").innerHTML = data.whats_madrigal;

        const mainFirstBlock = document.getElementById("main-blocks-1");
        const mainSecondBlock = document.getElementById("main-blocks-2");
        const mainThirdBlock = document.getElementById("main-blocks-3");
        const mainFourthBlock = document.getElementById("main-blocks-4");

        const blockManager = new BlockManager(data, new CardAnchorContainer());

        mainFirstBlock.appendChild(blockManager.generateBlock("main-first"));
        mainSecondBlock.appendChild(blockManager.generateBlock("main-second"));

        data.blocks.filter((el) => data.before_news_blocks.includes(el.id)).forEach((el) => {
            const box = document.createElement("div");
            box.id = el.id;
            const label = document.createElement("h1");
            label.textContent = el.label;

            box.appendChild(label);
            box.appendChild(blockManager.generateBlock(el.id));

            mainThirdBlock.append(box);
        });

        data.blocks.filter((el) => data.after_news_blocks.includes(el.id)).forEach((el) => {
            const box = document.createElement("div");
            box.id = el.id;
            const label = document.createElement("h1");
            label.textContent = el.label;

            box.appendChild(label);
            box.appendChild(blockManager.generateBlock(el.id));

            mainFourthBlock.append(box);
        });
    }),
    fetchNews({ pagesize: 3 }).then((data) => {
        const news = document.getElementById("news-block");

        data.elements.forEach((el) => {
            const card = document.createElement("a");
            card.href = constructHref("/news-details#" + el.id);
            card.classList.add("card");
            card.style.textDecoration = "none";

            // const cardImg = document.createElement("img");
            // cardImg.classList.add("card-img-top");
            // cardImg.src = (el.preview_image_url && process.env.NODE_ENV == "production") ? el.preview_image_url : dummyPicture;

            const cardBody = document.createElement("div");
            cardBody.classList.add("card-body", "d-flex", "flex-column");

            const cardTitle = document.createElement("h6");
            cardTitle.classList.add("card-title");
            cardTitle.textContent = el.title;

            const cardFooter = document.createElement("div");
            cardFooter.classList.add("card-footer", "text-body-secondary", "text-end");
            cardFooter.innerHTML = `<i class="bi bi-clock"></i> ${dateToString(new Date(el.publication_date))}`;

            cardBody.appendChild(cardTitle);

            // card.appendChild(cardImg);
            card.appendChild(cardBody);
            card.appendChild(cardFooter);

            news.appendChild(card);
        });
    })
]).then(() => {
    setTimeout(() => {
        if (window.location.hash.length > 1) {
            window.open(window.location.href, '_self')
        }
    }, 50)
})