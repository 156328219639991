import config from "../../config";
import { constructHref } from "./helpers";


class CardContainerProvider {
    make() {
        throw "Not implemented";
    }
}

class CardDivContainer {
    make(_) {
        return document.createElement("div");
    }
}

class CardAnchorContainer {
    make(el) {
        const container = document.createElement("a");
        container.href = constructHref("/info/" + el.id);

        return container;
    }
}

class BlockManager {
    /**
     * 
     * @param {object} data 
     * @param {CardContainerProvider} containerProvider
     */
    constructor(data, containerProvider) {
        this.data = data;
        this.containerProvider = containerProvider;
    }

    /**
     * Additional content for block: icon
     * 
     * @param {any} element
     * @param {HTMLDivElement} parent
     * @return {HTMLDivElement}
     */
    additionalContentIcon(element, parent) {
        const iconContainer = document.createElement("div");
        iconContainer.classList.add("text-end", "d-flex", "justify-content-end", "align-items-end", "flex-grow-1");

        const icon = document.createElement("object");
        const iconSrc = config.data_host + "/" + element.icon;
        icon.setAttribute('data', iconSrc)
        icon.setAttribute('type', 'image/svg+xml')
        icon.classList.add("img-fluid", "card-icon", "pe-none");

        parent.addEventListener('pointerenter', () => {
            icon.contentWindow.postMessage('hover-in')
        })
        parent.addEventListener('pointerleave', () => {
            icon.contentWindow.postMessage('hover-out')
        })

        iconContainer.appendChild(icon);

        return iconContainer;
    }

    /**
     * Additional content for block: slogan
     * 
     * @param {any} element
     * @param {HTMLDivElement} parent
     * @return {HTMLDivElement}
     */
    additionalContentSlogan(element, parent) {
        const textContainer = document.createElement("div");
        textContainer.classList.add("flex-grow-1", "d-flex", "justify-content-end", "align-items-end", "text-end");

        const slogan = document.createElement("small");
        slogan.classList.add("small", "text-muted");
        slogan.innerText = element.slogan;

        textContainer.appendChild(slogan);

        return textContainer;
    }

    /**
     * Additional content for block; router function
     * 
     * @param {any} element
     * @param {HTMLDivElement} parent
     * @return {HTMLDivElement}
     */
    makeAdditionalContent(element, parent) {
        if ("icon" in element) {
            return this.additionalContentIcon(element, parent);
        }
        if ("slogan" in element) {
            return this.additionalContentSlogan(element, parent);
        }
    }

    /**
     * Create block with cards
     * 
     * @param {string} blockId
     * @return {HTMLDivElement}
     */
    generateBlock(blockId) {
        const entry = this.data.blocks.find((el) => el.id == blockId);

        const mainContainer = document.createElement("div");
        mainContainer.classList.add("d-flex", "flex-md-row", "flex-column");

        const cardsContainer = document.createElement("div");
        cardsContainer.classList.add("d-flex", "flex-md-row", "flex-column", "gap-3", "cards-container", "flex-grow-1");

        entry.cards.forEach((el) => {
            const content = this.containerProvider.make(el);
            content.classList.add("card", "card-body", "container-card", "d-flex", "flex-column");

            const title = document.createElement("h4");
            title.innerText = el.title;

            content.appendChild(title);
            content.appendChild(this.makeAdditionalContent(el, content));
            if (!('icon' in el)) {
                content.classList.add('no-icon')
            }
            if (el.id === 'static') {
                content.classList.add('card-static')
            }

            cardsContainer.appendChild(content);
        })

        const prevButton = document.createElement("button");
        prevButton.classList.add("btn", "btn-link", "card-container-control", "card-container-control-prev");
        prevButton.style.fontSize = "1.5em";
        prevButton.innerHTML = `<i class="bi bi-arrow-left-circle"></i>`
        prevButton.style.visibility = "hidden";

        const nextButton = document.createElement("button");
        nextButton.classList.add("btn", "btn-link", "card-container-control", "card-container-control-next");
        nextButton.style.fontSize = "1.5em";
        nextButton.innerHTML = `<i class="bi bi-arrow-right-circle"></i>`;

        var currentIndex = 0;
        const containerNElements = Number(getComputedStyle(document.documentElement).getPropertyValue("--container-n-elements"));
        var maxN = Math.min(cardsContainer.children.length, containerNElements);
        if (containerNElements !== maxN) {
            cardsContainer.style.setProperty('--container-n-elements', maxN)
        }
        var maxI = cardsContainer.children.length - maxN;

        if (maxI == 0)
            nextButton.style.visibility = "hidden";

        prevButton.addEventListener("click", () => {
            nextButton.style.visibility = "visible";
            if (currentIndex == 1)
                prevButton.style.visibility = "hidden";

            currentIndex -= 1;

            cardsContainer.children[currentIndex].scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "start"
            });
        });
        nextButton.addEventListener("click", () => {
            prevButton.style.visibility = "visible";
            if (maxI - currentIndex == 1)
                nextButton.style.visibility = "hidden";

            currentIndex += 1;

            cardsContainer.children[currentIndex].scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "start"
            });
        });

        mainContainer.appendChild(prevButton);
        mainContainer.appendChild(cardsContainer);
        mainContainer.appendChild(nextButton);

        return mainContainer;
    }
}

export { BlockManager, CardAnchorContainer, CardDivContainer };